<template>
  <div
    :class="$route.path.includes('products') ? 'inside-product__wrapper2' : 'inside-product__wrapper' "
    style="
      flex-grow: 0.8;
      display: flex;
      flex-direction: column;
      align-items: flex;
    "
    :style="$route.path.includes('products') ? 'margin-top: 55px' : getStyle()"
  >
    <!-- <div
      class="table__header"
      style="margin-bottom: 13px !important;margin-top: -12px;"
    >
      <button
        v-if="$route.path.includes('create-order') && getDressColors.length == 0"
        v-b-modal.add-custom
        class="button"
        style="border-radius:6px"
        @click="getMaterialVByMandP"
      >
        <b-icon-plus />
        Add Custom Dress
      </button>
    </div> -->
    <div style="width: 100%;  padding-top: 0px">
      <table
        class="team_table"
        style="margin-top: 3px;"
        :class="{'button--disabled': getMaterialsByArticleNum.length == 0 }"
      >
        <thead>
          <tr>
            <th>
              {{ $t('MaterialVariantName') }}
            </th>
            <th>
              {{ $t('Photo') }}
            </th>
            <th>
              {{ $t('Unit') }}
            </th>
            <th>
              {{ $t('Details') }}
            </th>
          </tr>
        </thead>
        <tbody
          v-if="getMaterialsByArticleNum && getMaterialsByArticleNum.productSingleMaterialVariantDtos"
        >
          <tr
            v-for="item in getMaterialsByArticleNum.productSingleMaterialVariantDtos"
            :key="item.materialVariantId"
            style="cursor:unset;"
          >
            <td>
              {{ item.name }}
            </td>
            <td
              v-if="item.fileUrl"
              style="padding: 9px;"
            >
              <img
                v-b-tooltip="$t('ClickToViewImg')"
                style="height: 40px; border-radius: 3px; "
                :src="item.fileUrl"
                alt="image"
                @click.stop="getAllImages(item)"
              >
            </td>
            <td v-else>
              <img
                v-b-tooltip="$t('NoImageOrDocument')"
                style="width:30px;height:30px"
                src="../../assets/images/noImageAvailable.svg"
              >
            </td>
            <td>
              <template v-if="item.quantity > 0">
                {{ item.quantity }} {{ item.quantity === 1 ? $t('Part') : $t('Parts') }}
              </template>
              <template v-else-if="item.length > 0">
                {{ item.length }} {{ item.length === 1 ? $t('Meter') : $t('Meters') }}
              </template>
            </td>
            <td class="actiontd">
              <button
                v-if="!showStockLocation"
                class="editButton"
                @click="toggleStockLocation(item.materialVariantId, $event)"
              >
                {{ $t('Stock') }}
              </button>
              <button
                v-if="showStockLocation"
                class="editButton"
                @click="toggleStockLocation(item.materialVariantId, $event)"
              >
                {{ $t('Stock') }}
              </button>

              <div
                v-if="showPopup[item.materialVariantId]"
                class="popup stockPopUp"
              >
                <table class="team_table">
                  <thead>
                    <tr>
                      <th>{{ $t('Total') }}</th>
                      <th>{{ $t('Location') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="Array.isArray(getCurrentStockAmountByLocation)">
                    <tr
                      v-for="stock in getCurrentStockAmountByLocation"
                      :key="stock.locationName"
                    >
                      <td>{{ stock.totalStock }}</td>
                      <td>{{ stock.locationName }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td>{{ getCurrentStockAmountByLocation.totalStock }}</td>
                      <td>{{ getCurrentStockAmountByLocation.locationName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              colspan="4"
              style="text-align: center; background: #F9FAFB"
            >
              {{ $t('EstimatedProductionTime') }}
            </td>
          </tr>
          <tr
            style="cursor:unset;"
          >
            <td>
              <div style="display: flex; flex-direction: column; align-items: center; border: 2px solid #EAECF0; padding: 10px; width: 150px; background-color: #F9FAFB">
                <div>
                  <img
                    src="../../assets/images/prodmanagementTime.svg"
                    alt=""
                  >
                </div>
                <div class="minutes">
                  <span>{{ getMaterialsByArticleNum.estimattedProductionJson ? getMaterialsByArticleNum.estimattedProductionJson.patternInMinutes : 0 }} min</span>
                </div>
              </div>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; align-items: center; border: 2px solid #EAECF0; padding: 10px; width: 150px; background-color: #F9FAFB">
                <div>
                  <img
                    src="../../assets/images/sewingTime.svg"
                    alt=""
                  >
                </div>
                <div class="minutes">
                  <span>{{ getMaterialsByArticleNum.estimattedProductionJson ? getMaterialsByArticleNum.estimattedProductionJson.sewingInMinutes : 0 }} min</span>
                </div>
              </div>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; align-items: center; border: 2px solid #EAECF0; padding: 10px; width: 150px; background-color: #F9FAFB">
                <div>
                  <img
                    src="../../assets/images/embroideryTime.svg"
                    alt=""
                  >
                </div>
                <div class="minutes">
                  <span>{{ getMaterialsByArticleNum.estimattedProductionJson ? getMaterialsByArticleNum.estimattedProductionJson.embroideryInMinutes : 0 }} min</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      move-disabled
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
    <!-- <CreateCustomOrderModal
      @createCustomOrder="createCustomOrder"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import CreateCustomOrderModal from '@/modals/CreateCustomOrderModal.vue';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // CreateCustomOrderModal,
    VueEasyLightbox,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    productMatObj: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    addedArticle: {
      type: Array,
    },
    createOrder: {
      type: Boolean,
    },
  },
  data() {
    return {
      showStockLocation: false,
      showPopup: {},
      dummy: '',
      searchTerm: '',
      rows: [],
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
    };
  },
  computed: {
    ...mapGetters(['getMaterialsByArticleNum', 'getDressColors', 'getCurrentStockAmountByLocation']),
  },
  watch: {
    productMatObj() {
      this.addRows();
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['addOrderInStore', 'getMaterialVariantsToMDCPagination', 'stockLocationDetails', 'getCurrentStockAndLocationByVariantId']),
    async createCustomOrder(obj) {
      await this.addOrderInStore(obj);
    },
    toggleStockLocation(id, event) {
      event.stopPropagation(); // Prevents the click from closing the popup immediately
      this.showPopup = { ...this.showPopup, [id]: !this.showPopup[id] };
      if (this.showPopup[id]) {
        this.stockLocationDetails({ materialVariantId: id });
      } else {
        this.hideStockLocation();
      }
    },
    stockLocationDetails(value) {
      this.materialVariantId = value.materialVariantId
      this.showStockLocation = true
      this.getCurrentStockAndLocationByVariantId({ materialVariantId: value.materialVariantId })
    },
    hideStockLocation() {
      this.showStockLocation = false
    },
    handleClickOutside(event) {
      const popup = this.$el.querySelector('.popup');
      if (popup && !popup.contains(event.target)) {
        this.showPopup = {}; // Close all popups
      }
    },

    async getAllImages(item) {
      console.log('itemi', item)
      this.image.imgs = [];
      this.image.imgs.push({
        src: item.fileUrl,
      });
      this.show();
    },
    show() {
      this.image.visible = true
    },

    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    getStyle() {
      if (this.getDressColors.length != 0) {
        return 'margin-top: 55px'
      // eslint-disable-next-line no-else-return
      } else {
        return 'margin-top: 55px'
      }
    },
    addRows() {
      const x = this.productMatObj.productMaterialVariantDtos;
      const y = this.productMatObj.productMaterialQuantityDtos;

      this.rows = [...x, ...y];
    },

    // tdClass(row) {
    //   return row.vgtSelected ? 'row-clicked' : '';
    // },

    // onRowClick(p) {
    //   return p.selected ? 'selected' : '';
    // },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 270px;
  height: auto;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: white;
  position: absolute;
  top: calc(100% - 13px);
  left: -46%;
  transform: translateX(-50%);
  z-index: 1;
}

.actiontd {
  position: relative;
}
.orange-color {
  color: orange;
}
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
  width: 100% !important;
}

.team_table th {
  padding: 10px;
}

.team_table td {
  padding: 16px 9px !important;
}

.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.team_table tr {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  cursor: pointer;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .inside-product__wrapper{
    width: 15% !important;
    margin-left: 40px;
  }
}
@media screen and (max-width: 1500px) {
  .inside-product__wrapper{
    width: 100% !important;
    margin-left: 0px !important;
    flex-grow: 0 !important;
  }
}
@media screen and (max-width: 1190px) {
  .inside-product__wrapper {
    width: 100% !important;
  }
}
</style>
